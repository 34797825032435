import {
  CertificateCreate,
  CertificateEdit,
  CertificateList
} from 'pages/certificates_management/certificates';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'course_certificates',
  label: 'Certificados cursos avulsos',
  list: CertificateList,
  edit: CertificateEdit,
  create: CertificateCreate,
  actions: ['list', 'show', 'edit', 'create', 'delete'],
  parentName: 'certificate_management'
};

export default generateResources(resource);
