import { GroupCreate, GroupEdit, GroupList } from 'pages/epimetheus/groups';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'epimetheus/groups',
  label: 'Gerenciador de tags',
  list: GroupList,
  edit: GroupEdit,
  create: GroupCreate,
  actions: ['list', 'show', 'edit', 'create', 'delete'],
  parentName: 'teaching_resources'
};

export default generateResources(resource);
